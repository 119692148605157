//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-112:_64,_400,_1112,_8376,_3372,_9108,_5336,_5928;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-112')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-112', "_64,_400,_1112,_8376,_3372,_9108,_5336,_5928");
        }
      }catch (ex) {
        console.error(ex);
      }