import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/the-aeromotive-group/Templates/SearchPage.rt'
import { FacetPanel } from 'Components'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/_default-store/Templates/SearchBox.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonMobile from 'Stores/_default-store/Templates/searchBox/dialogButtonMobile.rt'
import { SearchResult } from 'Components'
import { SearchHeader } from 'Components'
import { RequestPanel } from 'Components'
import TemplateRequestPumpPanelContainer from 'Stores/the-aeromotive-group/Templates/RequestPumpPanelContainer.rt'
import TemplateRequestPumpDropdownPanel from 'Stores/the-aeromotive-group/Templates/RequestPumpDropdownPanel.rt'
import TemplateRequestPumpPanel from 'Stores/the-aeromotive-group/Templates/RequestPumpPanel.rt'
import { VehicleWidget } from 'Components'
import TemplatefitmentSearchhomeVehicleWidget from 'Stores/_default-store/Templates/fitmentSearch/homeVehicleWidget.rt'
import { Garage } from 'Components'
import TemplatefitmentSearchgarage from 'Stores/_default-store/Templates/fitmentSearch/garage.rt'
import TemplatefitmentSearchverifyFitment from 'Stores/the-aeromotive-group/Templates/fitmentSearch/verifyFitment.rt'
import { ProductData } from 'Components'
import TemplateproductproductData from 'Stores/the-aeromotive-group/Templates/product/productData.rt'
import { ContextDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/_default-store/Templates/fitmentSearch/dialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import TemplateCustomFacetDialog from 'Stores/the-aeromotive-group/Templates/CustomFacetDialog.rt'
import { DialogButton } from 'Components'

export const compMap = {
  SearchPage,
FacetPanel,
SearchBox,
SearchBoxDialogButton,
SearchResult,
SearchHeader,
RequestPanel,
VehicleWidget,
Garage,
ProductData,
ContextDialog,
SearchBoxDialog,
DialogButton
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'SearchBox': TemplateSearchBox,
'searchBox/dialogButtonMobile': TemplatesearchBoxdialogButtonMobile,
'RequestPumpPanelContainer': TemplateRequestPumpPanelContainer,
'RequestPumpDropdownPanel': TemplateRequestPumpDropdownPanel,
'RequestPumpPanel': TemplateRequestPumpPanel,
'fitmentSearch/homeVehicleWidget': TemplatefitmentSearchhomeVehicleWidget,
'fitmentSearch/garage': TemplatefitmentSearchgarage,
'fitmentSearch/verifyFitment': TemplatefitmentSearchverifyFitment,
'product/productData': TemplateproductproductData,
'fitmentSearch/dialog': TemplatefitmentSearchdialog,
'SearchBoxDialog': TemplateSearchBoxDialog,
'CustomFacetDialog': TemplateCustomFacetDialog
};